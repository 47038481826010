import React, { createContext, useState } from "react";

export const MyContext = createContext();

export const ContextProvider = ({ children }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Home");
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <MyContext.Provider value={{ activeMenuItem, setActiveMenuItem, isOpen, setIsOpen }}>
      {children}
    </MyContext.Provider>
  );
};

