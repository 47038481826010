import React, { useContext } from "react";
import HomeSection from "./homeSection";
import About from "./about";
import Services from "./services";
import Portfolio from "./portfolio/portfolio";
import ContactForm from "./contactForm";
import { MyContext } from "../context/useContext";

const sections = [
  { name: "Home", component: <HomeSection /> },
  { name: "About", component: <About /> },
  { name: "Services", component: <Services /> },
  { name: "Portfolio", component: <Portfolio /> },
  {
    name: "Contact",
    component: (
      <div className="section_inner">
        <div className="tg_contact">
          <div className="contact_inner">
            <div className="tg_title">
              <h3>Get in Touch</h3>
            </div>
            <div className="my_map">
              <div className="mapouter"></div>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
    ),
  },
];

function Mainpart() {
  const { activeMenuItem } = useContext(MyContext);

  return (
    <div className="tg_mainpart">
      <div className="mainpart_inner">
        {sections.map((section) => (
          <div
            key={section.name}
            className={
              activeMenuItem === section.name
                ? "tg_section animated"
                : "tg_section animated hidden"
            }
          >
            {section.component}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Mainpart;
