import React from "react";
import Skillbox from "../helpers/skillbox";
import Biography from "../helpers/biography";
import Experience from "../helpers/experience";

function About() {
  return (
    <div className="section_inner">
      <div className="tg_about">
        <Biography />
        <Skillbox />
        <Experience />
      </div>
    </div>
  );
}

export default About;
