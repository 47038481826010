import React from "react";

function HomeSection() {
  return (
    <div className="tg_home">
      <div className="content">
        <h2>Theodoros Giatsidis</h2>
        <div className="animateText">
          <span>Web Developer</span>
          <span>UI/UX Designer</span>
          <span>SEO Optimizer</span>
        </div>
      </div>
    </div>
  );
}

export default HomeSection;
