import React from "react";
import Home from "./pages/home";
import { HelmetProvider } from "react-helmet-async";
import { ContextProvider } from "./context/useContext";
import { SEO } from "./react-helmet/seo";

function App() {
  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <ContextProvider>
          <SEO />
          <Home />
        </ContextProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
