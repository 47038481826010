/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function Biography() {
  return (
    <>
    <div className="biography">
          <div className="tg_title">
            <h3>About Me</h3>
          </div>
          <div className="text">
            <p>
              Hello, I'm Theodoros Giatsidis, a Frontend Developer with 3 years
              of experience. I love turning ideas into user-friendly websites. I
              enjoy every step of the development process, from brainstorming to
              crafting seamless interfaces. Thanks for your consideration, and I
              look forward to bringing my passion and skills to your project.
            </p>
          </div>
          <div className="details">
            <ul>
              <li>
                <span>Name:</span>
                <span>Theodoros Giatsidis</span>
              </li>
              <li>
                <span>Age:</span>
                <span>27</span>
              </li>
              <li>
                <span>City:</span>
                <span>Athens, Greece</span>
              </li>
              <li>
                <span>Job:</span>
                <span>Frontend Developer</span>
              </li>
              <li>
                <span>Phone:</span>
                <span>
                  <a className="line_effect" href="tel:+306980704835">
                    +30 6980704835
                  </a>
                </span>
              </li>
              <li>
                <span>Email:</span>
                <span>
                  <a
                    className="line_effect"
                    href="mailto:tgiatsidis@hotmail.com"
                  >
                    <p>tgiatsidis@hotmail.com</p>
                  </a>
                </span>
              </li>
              <li>
                <span>Website:</span>
                <span>
                  <a className="line_effect" href="#">
                    www.tgiatsidis.gr
                  </a>
                </span>
              </li>
              <li>
                <span>Freelance:</span>
                <span>Available</span>
              </li>
            </ul>
          </div>
          <div className="tg_button">
            <a href="assets/img/cv/tgiatsidis-CV.pdf" downlaod>
              <span className="back">Download CV</span>
              <span className="front">Download CV</span>
            </a>
          </div>
        </div>
    </>
  )
}

export default Biography