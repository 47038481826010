import React from "react";
import { projects } from "../../helpers/projects";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./portfolio.css";

const Portfolio = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: true,
  };

  return (
    <div className="section_inner">
      <div className="tg_portfolio">
        <div className="portfolio_list">
          <div className="tg_title">
            <h3>My Portfolio</h3>
          </div>
          <Slider {...settings}>
            {projects.map((project, index) => (
              <div className="project" key={index}>
                <iframe
                  src={project.url}
                  title={project.title}
                  className="project-iframe"
                ></iframe>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
