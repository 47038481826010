import React from "react";

const ServiceItem = ({ iconSrc, title, items }) => (
  <li>
    <div className="list_inner">
      <img className="svg" src={iconSrc} alt="image1" width="40" height="40" />
      <h3 className="title">{title}</h3>
      <div className="list">
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </li>
);

const ServicesList = () => (
  <div className="services_list">
    <div className="tg_title">
      <h3>Top Notch Services</h3>
    </div>
    <ul>
      <ServiceItem
        iconSrc="assets/img/svg/web.svg"
        title="Web Development"
        items={["HTML Websites", "Wordpress Websites", "React Websites"]}
      />
      <ServiceItem
        iconSrc="assets/img/svg/physics.svg"
        title="Mobile Application"
        items={["Android Apps", "IOS Apps", "Huawei Apps"]}
      />
      <ServiceItem
        iconSrc="assets/img/svg/star.svg"
        title="SEO Optimization"
        items={["SEO Website", "Code Validation", "GTmetrix Pro"]}
      />
      <ServiceItem
        iconSrc="assets/img/svg/anchor.svg"
        title="Creative Design"
        items={["Figma Design", "PSD Design", "Sketch Design"]}
      />
    </ul>
  </div>
);

const SectionInner = () => (
  <div className="section_inner">
    <div className="tg_services">
      <ServicesList />
    </div>
  </div>
);

export default SectionInner;
