export const projects = [
  {
    title: "Petridens",
    description: "Wordpress Website",
    url: "https://petridens.gr",
  },
  {
    title: "VK Gentlemans Culture",
    description: "Wordpress Website",
    url: "https://vkgentlemansculture.com/",
  },
  {
    title: "Meno Homes",
    description: "Wordpress Website",
    url: "https://meno.homes/",
  },
];
