import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    contact_name: "",
    contact_email: "",
    contact_message: "",
  });

  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate Name
    if (!formData.contact_name.trim()) {
      newErrors.contact_name = "Name is required";
      valid = false;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !formData.contact_email.trim() ||
      !emailRegex.test(formData.contact_email.trim())
    ) {
      newErrors.contact_email = "Please enter a valid email address";
      valid = false;
    }

    // Validate Message
    if (!formData.contact_message.trim()) {
      newErrors.contact_message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const response = await fetch("https://formspree.io/f/mkndbgjd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.contact_name,
          email: formData.contact_email,
          message: formData.contact_message,
        }),
      });

      if (response.ok) {
        console.log("Form submitted successfully!");
        setSubmissionStatus("success");
        setFormData({
          contact_name: "",
          contact_email: "",
          contact_message: "",
        });
      } else {
        console.error("Error submitting form");
        setSubmissionStatus("error");
      }
    } else {
      console.log("Form is invalid");
      setSubmissionStatus(null);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="form_wrapper">
      <form id="contactForm" onSubmit={handleSubmit}>
        <ul>
          <li>
            <input
              type="text"
              placeholder="Name"
              name="contact_name"
              className={`${
                errors.contact_name ? "error cf-form-control" : ""
              }`}
              value={formData.contact_name}
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{errors.contact_name}</span>
          </li>
          <li>
            <input
              type="email"
              placeholder="Email"
              name="contact_email"
              className={`${
                errors.contact_email ? "error cf-form-control" : ""
              }`}
              value={formData.contact_email}
              onChange={handleChange}
            />
            <span style={{ color: "red" }}>{errors.contact_email}</span>
          </li>
          <li id="text-area-w">
            <textarea
              placeholder="Message"
              name="contact_message"
              className={`${
                errors.contact_message ? "error cf-form-control" : ""
              }`}
              value={formData.contact_message}
              onChange={handleChange}
            ></textarea>
            <span style={{ color: "red" }}>{errors.contact_message}</span>
          </li>
        </ul>

        <div className="tg_button">
          <button type="submit" id="send_message">
            <span className="front">Submit</span>
          </button>
        </div>
      </form>
      {submissionStatus === "success" && (
        <span style={{ color: "green", marginTop: '10px' }}>Form submitted successfully!</span>
      )}
    </div>
  );
};

export default ContactForm;
