import React from "react";

function Experience() {
  return (
    <>
      <div className="experience">
        <div className="tg_title">
          <h3>Timeline</h3>
        </div>
        <div className="wrapper">
          <div className="left">
            <div className="experience_title">
              <h3>Working Experience</h3>
            </div>
            <div className="tg_experience_list">
              <ul>
                <li>
                  <div className="list_inner">
                    <div className="subject">
                      <h3>Hosthub</h3>
                      <span>Web Designer</span>
                    </div>
                    <div className="date">
                      <span>2023-now</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="list_inner">
                    <div className="subject">
                      <h3>BetAdvanced</h3>
                      <span>Frontend Developer</span>
                    </div>
                    <div className="date">
                      <span>2021-now</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="right">
            <div className="experience_title">
              <h3>Education</h3>
            </div>
            <div className="tg_experience_list">
              <ul>
                <li>
                  <div className="list_inner">
                    <div className="subject">
                      <h3>University of Thessaly</h3>
                      <span>
                        BSc Computer Science, University of Thessaly, Lamia
                        (Greece 🇬🇷)
                      </span>
                    </div>
                    <div className="date">
                      <span>2016-2023</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Experience;
