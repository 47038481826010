import React from "react";
import { Helmet } from "react-helmet-async";

export const SEO = () => {
  return (
    <div>
      <Helmet>
        <title>Προγραμματιστής εφαρμογών, ιστοσελίδων | Αθήνα</title>
      </Helmet>

      <header>
        <h1 style={{ display: "none" }}>
          Προγραμματιστής εφαρμογών, ιστοσελίδων | Αθήνα!
        </h1>
      </header>
    </div>
  );
};
