/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Social from "../helpers/social";
import { MyContext } from "../context/useContext";

function MobileSidebar() {
  const { activeMenuItem, setActiveMenuItem } = useContext(MyContext);
  const { isOpen, setIsOpen } = useContext(MyContext);

  const backgroundImage = "assets/img/logo/img.jpg";

  const handleOpenMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <>
      <div className="tg_topbar">
        <div className="topbar_inner">
          <div className="logo" data-type="avatar">
            <div
              className="avatar"
              onClick={() => window.location.reload()}
              style={{
                backgroundImage: `url('${backgroundImage}')`,
              }}
            ></div>
            <div className="text">
              <h3>Theodoros</h3>
            </div>
          </div>
          <div className="trigger" onClick={handleOpenMenu}>
            <div
              className={
                isOpen
                  ? "hamburger hamburger--slider is-active"
                  : "hamburger hamburger--slider"
              }
            >
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={isOpen ? "tg_mobile_menu opened" : "tg_mobile_menu"}>
        <div className="inner">
          <div className="wrapper">
            <div className="logo" data-type="avatar">
              <div
                className="avatar"
                style={{
                  backgroundImage: `url('${backgroundImage}')`,
                }}
              ></div>
              <div className="text">
                <h3>Theodoros</h3>
              </div>
            </div>
            <div className="menu_list">
              <ul className="transition_link">
                <li className={activeMenuItem === "Home" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      handleMenuClick("Home");
                      handleOpenMenu(false);
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className={activeMenuItem === "About" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      handleMenuClick("About");
                      handleOpenMenu(false);
                    }}
                  >
                    About
                  </a>
                </li>
                <li className={activeMenuItem === "Services" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      handleMenuClick("Services");
                      handleOpenMenu(false);
                    }}
                  >
                    Services
                  </a>
                </li>
                <li className={activeMenuItem === "Portfolio" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      handleMenuClick("Portfolio");
                      handleOpenMenu(false);
                    }}
                  >
                    Portfolio
                  </a>
                </li>
                <li className={activeMenuItem === "Contact" ? "active" : ""}>
                  <a
                    href="#"
                    onClick={() => {
                      handleMenuClick("Contact");
                      handleOpenMenu(false);
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <Social />
            <div className="copyright">
              <p>Copyright &copy; 2024</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileSidebar;
