/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef } from "react";

function Skillbox() {
  const skillboxRefs = Array.from({ length: 6 }, () => useRef(null));
  const skillboxPercent = Array.from({ length: 6 }, () => useRef(null));
  const skillboxBarsStyles = Array.from({ length: 6 }, () => useRef(null));

  const skillsData = [
    { label: "HTML", percent: 85, index: 0 },
    { label: "Javascript", percent: 95, index: 1 },
    { label: "CSS", percent: 75, index: 2 },
    { label: "React JS", percent: 100, index: 3 },
    { label: "Wordpress", percent: 90, index: 4 },
    { label: "Laravel", percent: 80, index: 5 },
  ];

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          skillsData.forEach(({ index, percent }) => {
            const labelRef = skillboxRefs[index].current;
            const percentRef = skillboxPercent[index].current;
            const barStyleRef = skillboxBarsStyles[index].current;

            labelRef.classList.remove("label");
            labelRef.classList.add("label", "opened");

            percentRef.style.right = "15%";

            barStyleRef.classList.remove("bar");
            barStyleRef.classList.add("bar", "open");

            barStyleRef.style.width = `${percent}%`;
            barStyleRef.style.backgroundColor = "#999999";
          });
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    skillboxRefs.forEach((ref) => ref.current && observer.observe(ref.current));

    return () => {
      // Cleanup the observer when the component is unmounted
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures the effect runs once on mount

  return (
    <div className="skillbox">
      <div className="tg_title">
        <h3>Knowledge</h3>
      </div>
      <div className="wrapper">
        {["left", "right"].map((position, i) => (
          <div className={position} key={i}>
            <div className="skills_title">
              <h3>Programming Skills</h3>
            </div>
            <div className="arlo_progress">
              {skillsData
                .slice(i * 3, (i + 1) * 3)
                .map(({ label, percent, index }) => (
                  <div
                    className="progress_inner"
                    data-value={percent}
                    data-color="#999"
                    key={index}
                  >
                    <span>
                      <span className="label" ref={skillboxRefs[index]}>
                        {label}
                      </span>
                      <span className="number" ref={skillboxPercent[index]}>
                        {percent}%
                      </span>
                    </span>
                    <div className="background">
                      <div className="bar" ref={skillboxBarsStyles[index]}>
                        <div className="bar_in"></div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skillbox;
