import React from "react";

function Social() {
  return (
    <div className="social">
      <ul>
        <li>
          <a href="https://www.facebook.com/teo.giatsidis">
            <i className="fab fa-facebook-square"></i>
            <span className="sr-only">Follow us on Facebook</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/theodorosgiatsidis/">
            <i className="fab fa-linkedin"></i>
            <span className="sr-only">Follow us on LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/tgiatsidis/">
            <i className="fab fa-instagram-square"></i>
            <span className="sr-only">Follow us on Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://github.com/theodorosgiatsidis">
            <i className="fab fa-github"></i>
            <span className="sr-only">Follow us on Github</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Social;
