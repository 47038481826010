/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import Social from "../helpers/social";
import { MyContext } from "../context/useContext";

function Sidebar() {
  const { activeMenuItem, setActiveMenuItem } = useContext(MyContext);
  const backgroundImage = "assets/img/logo/img.jpg";

  const handleMenuClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <div className="tg_sidebar_menu">
      <div className="sidebar_inner">
        <div className="logo" data-type="avatar">
          <div
            className="avatar"
            style={{
              backgroundImage: `url('${backgroundImage}')`,
            }}
          ></div>
          <div className="image">
            <img src={backgroundImage} alt="image1" width="90" height="90" />
          </div>
          <div className="text">
            <h3>Theodoros</h3>
          </div>
        </div>
        <div className="menu">
          <ul className="transition_link">
            <li className={activeMenuItem === "Home" ? "active" : ""}>
              <a href="#" onClick={() => handleMenuClick("Home")}>
                Home
              </a>
            </li>
            <li className={activeMenuItem === "About" ? "active" : ""}>
              <a href="#" onClick={() => handleMenuClick("About")}>
                About
              </a>
            </li>
            <li className={activeMenuItem === "Services" ? "active" : ""}>
              <a href="#" onClick={() => handleMenuClick("Services")}>
                Services
              </a>
            </li>
            <li className={activeMenuItem === "Portfolio" ? "active" : ""}>
              <a href="#" onClick={() => handleMenuClick("Portfolio")}>
                Portfolio
              </a>
            </li>
            <li className={activeMenuItem === "Contact" ? "active" : ""}>
              <a href="#" onClick={() => handleMenuClick("Contact")}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="copyright">
          <p>
            Copyright &copy; 2024 by
            <a className="line_effect" href="#">
              &nbsp;tgiatsidis.gr
            </a>
          </p>
          <p>All rights are reserved</p>
        </div>
        <Social />
      </div>
    </div>
  );
}

export default Sidebar;
