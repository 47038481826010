import React from "react";
import MobileSidebar from "../sidebar/mobileSidebar";
import Sidebar from "../sidebar/sidebar";
import Mainpart from "./mainpart";

function Home() {
  React.useEffect(() => {
    const preloader = document.getElementById("preloader");

    const preloaderTimeout = setTimeout(() => {
      preloader.classList.add("preloaded");
    }, 800);

    const removePreloaderTimeout = setTimeout(() => {
      preloader.remove();
    }, 2000);

    return () => {
      clearTimeout(preloaderTimeout);
      clearTimeout(removePreloaderTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="preloader">
        <div className="loader_line"></div>
      </div>
      <div className="tg_all_wrap" data-enter="headShake" data-exit>
        <div className="tg_background_effects" data-style="noise"></div>
        <MobileSidebar />
        <Sidebar />
        <Mainpart />
      </div>
    </>
  );
}

export default Home;
